import React from "react"
import { Link } from "gatsby"
import { PhotoPlaceholder } from "react-placeholder-image"
import { GiStoneThrone } from "react-icons/gi"

import Layout from "../components/layout"
import SEO from "../components/seo"

const WorkPage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="grid-2-2 has-mb-5">
      <Link to="/work/gym">
        <div
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
          data-sal-duration="900"
          style={{ minHeight: "70vh", cursor: "pointer" }}
        >
          <img className="is-fit" src={"/images/gym/1.jpg"} alt="" />
        </div>
      </Link>
      <Link to="/work/agave">
        <div
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
          data-sal-duration="900"
          style={{ minHeight: "70vh", cursor: "pointer" }}
        >
          <img className="is-fit" src={"/images/agave/1.jpg"} alt="" />
        </div>
      </Link>
      <Link to="/work/fruitsmith">
        <div
          data-sal="slide-up"
          data-sal-delay="400"
          data-sal-easing="ease"
          data-sal-duration="900"
          style={{ minHeight: "70vh", cursor: "pointer" }}
        >
          <img className="is-fit" src={"/images/fsmith/1.jpg"} alt="" />
        </div>
      </Link>
      <Link to="/work/karuneshwari">
        <div
          data-sal="slide-up"
          data-sal-delay="400"
          data-sal-easing="ease"
          data-sal-duration="900"
          style={{ minHeight: "70vh", cursor: "pointer" }}
        >
          <img className="is-fit" src={"/images/karuneshwari/1.jpg"} alt="" />
        </div>
      </Link>
      <Link to="/work/stimulate">
        <div
          data-sal="slide-up"
          data-sal-delay="600"
          data-sal-easing="ease"
          data-sal-duration="900"
          style={{ minHeight: "70vh", cursor: "pointer" }}
        >
          <img className="is-fit" src={"/images/stimulate/1.jpg"} alt="" />
        </div>
      </Link>
      <Link to="/work/thelightear">
        <div
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
          data-sal-duration="900"
          style={{ minHeight: "70vh", cursor: "pointer" }}
        >
          <img className="is-fit" src={"/images/thelightear/1.jpg"} alt="" />
        </div>
      </Link>
      <Link to="/work/yogsattva">
        <div
          data-sal="slide-up"
          data-sal-delay="400"
          data-sal-easing="ease"
          data-sal-duration="900"
          style={{ minHeight: "70vh", cursor: "pointer" }}
        >
          <img className="is-fit" src={"/images/yogsattva/1.jpg"} alt="" />
        </div>
      </Link>
      <Link to="/work/tamasha">
        <div
          data-sal="slide-up"
          data-sal-delay="600"
          data-sal-easing="ease"
          data-sal-duration="900"
          style={{ minHeight: "70vh", cursor: "pointer" }}
        >
          <img className="is-fit" src={"/images/tamasha/1.jpg"} alt="" />
        </div>
      </Link>
    </div>
  </Layout>
)

export default WorkPage
